// A modernized Official Languages Act
// History of the act timeline
.node-60024{
  .timeline {
    border-left: 4px solid grey;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: white;
    color: black;
    margin: 10px auto;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1em;
    font-size: 1.03em;
    padding: 50px;
    list-style: none;
    text-align: left;
    font-weight: 100;
    max-width: 70%;
  }

  .timeline .event {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 10px;
    position: relative;
  }
  .timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
  .timeline .event:before,
  .timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
  }
  .timeline .event:before {
    left: -217.5px;
    color: black;
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 1em;
    min-width: 140px;
  }
  .timeline .event:after {
    box-shadow: 0 0 0 4px grey;
    left: -57.85px;
    background: #313534;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
    top: 5px;
  }
}