.padded{
    @include padded;

    &-sm{
        @include padded-small;
    }

    &-md{
        @include padded-medium;
    }

    &-lg{
        @include padded-large;
    }
}
.vertical-padding{
    @include vertical-padding;

    &-sm{
        @include vertical-padding-small;
    }

    &-lg{
        @include vertical-padding-large;
    }
}

.no-padding{
    @include no-padding;

    &-x{
        @include no-padding-x;
    }

    &-y{
        @include no-padding-y;
    }
}

.np {
    padding: 0 !important;
  
    &-top {
      padding-top: 0 !important;
    }
    &-right {
      padding-right: 0 !important;
    }
    &-bottom {
      padding-bottom: 0 !important;
    }
    &-left {
      padding-left: 0 !important;
    }
    &-x {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    &-y {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .d-flex {display: flex;}
  .flex-row            { flex-direction: row !important; }
  .flex-column         { flex-direction: column !important; }
  .flex-row-reverse    { flex-direction: row-reverse !important; }
  .flex-column-reverse { flex-direction: column-reverse !important; }

  .flex-wrap         { flex-wrap: wrap !important; }
  .flex-nowrap       { flex-wrap: nowrap !important; }
  .flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

  .justify-content-start   { justify-content: flex-start !important; }
  .justify-content-end     { justify-content: flex-end !important; }
  .justify-content-center  { justify-content: center !important; }
  .justify-content-between { justify-content: space-between !important; }
  .justify-content-around  { justify-content: space-around !important; }

  .align-items-start    { align-items: flex-start !important; }
  .align-items-end      { align-items: flex-end !important; }
  .align-items-center   { align-items: center !important; }
  .align-items-baseline { align-items: baseline !important; }
  .align-items-stretch  { align-items: stretch !important; }

  .align-content-start   { align-content: flex-start !important; }
  .align-content-end     { align-content: flex-end !important; }
  .align-content-center  { align-content: center !important; }
  .align-content-between { align-content: space-between !important; }
  .align-content-around  { align-content: space-around !important; }
  .align-content-stretch { align-content: stretch !important; }

  .align-self-auto     { align-self: auto !important; }
  .align-self-start    { align-self: flex-start !important; }
  .align-self-end      { align-self: flex-end !important; }
  .align-self-center   { align-self: center !important; }
  .align-self-baseline { align-self: baseline !important; }
  .align-self-stretch  { align-self: stretch !important; }

.justify {
    &-start {
        justify-content: flex-start;
    }
    &-end {
        justify-content: flex-end;
    }
    &-center {
        justify-content: center;
    }
    &-between {
        justify-content: space-between;
    }
    &-around {
        justify-content: space-around;
    }
    &-evenly {
        justify-content: space-evenly;
    }
}

.align {
    &-start {
        align-items: flex-start;
    }
    &-end {
        align-items: flex-end;
    }
    &-center {
        align-items: center;
    }
}
.align-center.wxt-media-default{
    img{
        margin:0 auto;
    }
}

.cropped-visually{
    max-height: 183px;
    overflow: hidden;
}
.element-invisible{
    @extend .sr-only;
}
.d-block{
    display: block!important;
}

.border-radius{
  &-bottom-right{
    border-bottom-right-radius: 50px;
  }
  &-bottom-left{
    border-bottom-left-radius: 50px;
  }
  &-top-right{
    border-top-right-radius: 50px;
  }
  &-top-left{
    border-top-left-radius: 50px;
  }
}