.page-node-type-publications{
    h1{
        font-size: 44px;
        line-height: 63px;
        font-weight: 600;
        border-bottom:0px;
    }
}
@media screen and (max-width:768px) {
    article.publications.feed{
        margin-left: 10px;
    }
}
