#wb-lng{
    padding-top:23px;
    .language-link{
        color: $brand-primary;
        font-weight: bold;
        
        abbr{
            font-size: 16px!important;
            margin-top: 0;
        }
    }
}
#mb-pnl{
    .language-link{
        color: $white;
        font-weight: bold;
        
        abbr{
            font-size: 16px!important;
            margin-top: 0;
            color:$white;
        }
    }
}