.js-webform-states-hidden .wb-overlay.bg-primary{
    header.navy-back{
        background-color: transparent;
        color:$white;
    }
    h2,h3,h4,h5,.h2,.h3,.h4,.h5{
        color:$white!important;
    }
}
.wb-overlay{
    z-index:10000;
}