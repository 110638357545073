blockquote {
    border: none;
    text-align: right;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-left: 8px solid $brand-orange;
    position: relative;
    margin-top: 45px;
    margin-bottom: 45px;
  
    @media screen and (min-width: $screen-md-max) {
      padding: 25px 200px;
    }
    @media screen and (max-width: $screen-md-max) {
      padding: 15px 25px;
    }
  
    &::before {
      content: "";
      background-image: url('../assets/svg/quote.svg');
      width: 42.1px;
      height: 39.4px;
      @media screen and (min-width: $screen-md-max) {
        position: absolute;
        top: 35px;
        left: 120px;
      }
      @media screen and (max-width: $screen-md-max) {
        display: inline-block;
      }
    }
    &::after {
        content: "";
        background-image: url('../assets/svg/quote.svg');
        transform:rotate(180deg);
        width: 42.1px;
        height: 39.4px;
        @media screen and (min-width: $screen-md-max) {
          position: absolute;
          bottom: 35px;
          right: 120px;
        }
        @media screen and (max-width: $screen-md-max) {
          display: inline-block;
        }
      }
  
    h1,
    h2,
    h3,
    h4 {
      font-size: 24px;
      font-weight: 600;
    }
  
    p {
      color: #5b5b5b;
      letter-spacing: 0.04em;
      font-weight: 400;
    }
  
    footer {
      color: #373737;
    }
  }
  .lang-fr blockquote::before,
  .lang-fr blockquote::after{
    background-image: url('../assets/svg/quote-french.svg');
    height: 44px;
  }