.view-timeline{
  &-filter{
    &.view-display-id-mobile{
      @media screen and (max-width: $screen-sm-max) {
        padding-right: $padding!important;
      }
    }
    @media print {
      display: none!important;
    }
  }

  &-content{
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;

      &-wrapper{
        background: transparentize($gray-darker, .75);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
      }

      &:before, 
      &:after{
        content: '';
        border-radius: 50%;
        position: absolute;
        inset: 0;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
      }

      &:after {
        box-shadow: 0 2px 0 $brand-orange inset;
        animation: rotate 2s linear infinite;
      }

      @keyframes rotate {
        0% {  transform: rotate(0)}
        100% { transform: rotate(360deg)}
      }
    }
  }

  &-search{
    .view-filters{
      @include padded;
      @extend .bg-secondary;
      @extend .border-radius-bottom-right;

      .form{
        &-inline{
          @media screen and (min-width: $screen-md-min) {
            display: flex;
            align-items: flex-end;
          }
        }

        &-group{
          flex-grow: 1;
        }

        &-item{
          margin-top: 0;

          label{
            line-height: normal;
          }

          @media screen and (max-width: $screen-sm-max) {
            margin-right: 0;
            width: 100%;
          }
        }

        &-control{
          width: 100%;
        }

        &-actions{
          float: left;

          @media screen and (min-width: $screen-md-min) {
            display: flex;
          }

          @media screen and (max-width: $screen-sm-max) {
            margin-bottom: 0;
            margin-top: 5px;
          }

          .btn{
            flex-grow: 1;
            line-height: normal;
            span.glyphicon{
              display: none;
            }

            + .btn{
              margin-left: 10px;
            }
          }
        }
      }
    }

    .view-content{
      .timeline{
        &-group{
          margin-left: 0;
          @media screen and (min-width: $screen-md-min) {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.timeline{
  &-banner{
    margin-bottom: $padding-xs;
    overflow: hidden;
    position: relative;

    figcaption{
      @include padded;
      bottom: 0;
      position: absolute;
      
      span{
        font-weight: 400;
      }

      strong{
        display: block;
        font-weight: 800;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      max-height: 480px;
      overflow: hidden;
    }

    &-bottom{
      margin-bottom: $padding-xs;

      @media screen and (min-width: $screen-md-min) {
        align-items: center;
        display: flex;
      }
    }
  }

  &-search{
    &-form{
      flex-grow: 1;

      .form-inline{
        align-items: flex-end;
        display: flex;
        width: 100%;

        .form-group{
          display: inline-block;

          &:first-of-type{
            @media screen and (max-width: $screen-sm-max) {
              flex-grow: 1;
            }
          }

          + .form-group{
            margin-left: 5px;
          }
        }

        button.btn{
          @media screen and (max-width: $screen-sm-max) {
            height: 42px;
            text-align: center;

            span{
              @include sr_only;
            }
          }
        }
      }
    }
  }

  &-randomizer{
    text-align: right;
    margin-bottom: 15px;

    @media print {
      display: none;
    }

    &-btn{
      align-items: center;
      background: $brand-orange;
      border: none;
      color: $brand-primary!important;
      font-weight: 700;
      justify-content: center;
      
      .icon{
        padding: 0;
        margin-left: 10px;
        
        svg{
          height: $font-size-h4;
          position: relative;
          top: 2px;
          transition: all 400ms ease-in-out;
          
          path{
            fill: $brand-primary;
            transition: all 400ms ease-in-out;
          }
        }
      }
      
      &:hover,
      &:focus{
        background-color: $brand-primary;
        color: $brand-teal!important;
        
        .icon{
          svg{
            path{
              fill: $brand-teal;
            }
          }
        }
      }
      
      @media screen and (min-width: $screen-md-min) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        display: inline-flex;
        font-size: $font-size-h3;
        padding: $padding-xs $padding;
      }

      @media screen and (max-width: $screen-sm-max) {
        display: flex;

        .icon{
          min-height: auto;
          min-width: auto;

          svg{
            height: $font-size-h5;
          }
        }
      }
    }
  }

  &-return{
    text-align: right;
    margin-bottom: $padding-xs;

    &-btn{
      align-items: center;
      background: $brand-orange;
      border-radius: 12px 0 0 12px;
      color: $brand-primary;

      .icon{
        font-size: 0;
        line-height: 0;
        padding: 0;
        margin-left: 10px;
        min-height: auto;
        min-width: auto;
        vertical-align: bottom;

        svg{
          fill: $brand-primary;
          transition: all 400ms ease-in-out;
        }
      }

      &:visited{
        color: $brand-primary;
      }

      &:hover,
      &:focus{
        background: $brand-primary;
        color: $brand-orange;

        .icon{
          svg{
            fill: $brand-orange;
          }
        }
      }
    }
  }

  &-menu{
    > li{
      > a{
        background: $white;
        border: 2px solid $brand-primary!important;
        border-radius: 0!important;
        color: $text-color!important;
        text-decoration: none!important;
      }

      &.dropdown{
        > a{
          .caret{
            border-top-color: $brand-orange;
            border-width: 7px;
            margin-left: 5px;
            margin-top: -3px;
            transition: all 300ms ease-in-out;
          }
        }

        .dropdown-menu{
          border-radius: 0;
          left: 50%;
          padding: 0;
          transform: translateX(-50%);

          a {
            text-align: center;
          }

          li.active{
            a{
              color: $white;
            }
          }
        }

        &.open,
        &:focus,
        &:focus-within{
          > a{
            border: $brand-primary;
          }

          .caret{
            transform: rotate(-90deg);
          }
        }
      }

      &.active{
        > a{
          color: $white!important;
        }
      }

      + li{
        > a{
          border-left: none!important;
        }
      }
    }
  }

  &-group{
    border-block: none!important;
    border-left: 3px dashed transparent!important;
    margin-bottom: 0;
    margin-left: 10px;
    position: relative;

    @media screen and (min-width: $screen-md-min) {
      padding-left: $padding-large;
    }

    @media screen and (max-width: $screen-sm-max) {
      border-color: $brand-orange!important;
      padding: 0;
      visibility: visible!important;
    }
    
    summary{
      border: none!important;
      padding-bottom: 0;
      padding-top: 0;
      &::marker{
        color:$brand-orange;
        content:'';
      }

      @media screen and (max-width: $screen-sm-max) {
        padding-left: ($padding-xs * 2);
        margin: 0;
      }

      &:before{
        transition: all .3s ease-in-out;
        content: "";
        vertical-align: middle;
        background: $brand-orange;
        border-radius: 50%;
        display: block;
        height: 18px;
        left: 0;
        margin: 0;
        position: absolute;
        top: 7px;
        transform: translateX(-10px)!important;
        width: 18px;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 9px solid $brand-orange;
        
        @media screen and (min-width: $screen-md-min) {
          top: 18px;
        }
      }

      .summary{
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid $brand-orange;
        content: '';
        display: inline-block;
        filter: drop-shadow(1px 0px 0px $brand-primary) drop-shadow(0 1px 0px $brand-primary) drop-shadow(0px -1px 0px $brand-primary);
        transition: all 0.3s ease-in-out;

        @media screen and (min-width: $screen-md-min) {
          margin-left: $padding;
        }

        @media screen and (max-width: $screen-sm-max) {
          border-width: 10px;
          margin-left: $padding-xs;
        }
      }
    }

    &[open]{
      border-color: $brand-orange!important;

      summary{
        .summary{
          transform: rotate(90deg);
        }
      }
    }

    &-inner{
      position: relative;
    }

    &-title{
      display: inline-block!important;
      text-align: center;
      margin: 0;
      min-width: 190px;
    }

    &-line{
      background: $brand-orange;
      height: 100%;
      left: 50%;
      position: absolute;
      transform: translatex(-50%);
      width: 2px;

      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }

    @media screen and (max-width: $screen-sm-max) {
      + .timeline-group{
        padding-top: $padding-xs;

        summary{
          &:before{
            top: 22px;
          }
        }
      }
    }
  }

  &-item{
    @media screen and (min-width: $screen-md-min) {
      text-align: right;
    }

    &-node{
      figure{
        @include clearfix;

        img{
          margin-bottom: 5px;

          @media screen and (min-width: $screen-md-min) {
            float: right;
          }
        }

        figcaption{
          @media screen and (min-width: $screen-md-min) {
            float: left;
          }
        }
      }
      
      
      &-title{
        &:focus,
        &:focus-visible{
          outline: 1px dotted;
        }
      }
      
      &-date{
        display: block;
        font-size: 120%;
        font-weight: 800;
      }
      
      &-indicator{
        background: $brand-orange;
        border-radius: 50%;
        height: 18px;
        position: absolute;
        width: 18px;
        
        @media screen and (min-width: $screen-md-min) {
          right: -9px;
        }

        @media screen and (max-width: $screen-sm-max) {
          height: 13px;
          left: -8px;
          top: 20px;
          width: 13px;
        }
      }

      &.block{
        transform: translateX($padding-xs)!important;

        &.in-view{
          transform: translateX(0)!important;
        }
      }
    }

    &:nth-of-type(odd){
      .timeline-item-node{
        @media screen and (min-width: $screen-md-min) {
          padding-left: 0;
        }
      }
    }

    &:nth-of-type(even){
      @media screen and (min-width: $screen-md-min) {
        text-align: left;
      }

      .timeline-item-node{
        @media screen and (min-width: $screen-md-min) {
          float: right;
          padding-right: 0;

          figure{
            img{
              float: left;
            }
          }

          &-indicator{
            right: auto;
            left: -9px;
          }
  
          &.block{
            transform: translateX(-#{$padding-xs})!important;
  
            &.in-view{
              transform: translateX(0)!important;
            }
          }
        }
      }
    }
  }

  &-pager{
    @media print {
      display: none;
    }
    .pager{
      li{
        @media screen and (min-width: $screen-md-min) {
          padding: 0 $padding-xs;
        }

        a{
          align-items: center;
          justify-content: center;
          background: $brand-teal;
          border-radius: $padding-xs!important;
          border-color: transparent;
          color: $brand-primary;
          display: inline-flex;
          font-size: $font-size-h3;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: normal;
          margin-bottom: 0;
          padding: 10px $padding;
          transition: all 300ms;

          @media screen and (max-width: $screen-sm-max) {
            font-size: $font-size-h4;
            margin-bottom: $padding-xs;
          }

          *{
            transition: auto;
          }

          .arrow{
            display: inline-block;
            height: 0;
            width: 0;

            &-left{
              border-right: 12px solid $brand-primary;
              border-top: 12px solid transparent;
              border-bottom: 12px solid transparent;
              margin-right: $padding-xs;
            }

            &-right{
              border-left: 12px solid $brand-primary;
              border-top: 12px solid transparent;
              border-bottom: 12px solid transparent;
              margin-left: $padding-xs;
            }
          }

          &:hover,
          &:focus{
            background: $brand-primary;
            color: $brand-teal;

            .arrow{
              &-left{
                border-right-color: $brand-teal;
              }
              &-right{
                border-left-color: $brand-teal;
              }
            }
          }
        }
      }
    }
  }
}