.decisions{
    .field--label-inline{
        .field--label{
            display: inline-block;
            font-weight: bold;
            &::after{
                content:':'
            }
        }
        .field--item,
        .field--items{
            display: inline-block;
        }
    }
}
.lang-fr{
    .decisions{
        .field--label-inline{
            .field--label{
                &::after{
                    content:' :'
                }
            }
        }
    }
}