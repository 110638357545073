
#wb-info{
    position: relative;
    z-index:0;
    &:before{
        content: '';
        position: absolute;
        top:-110px;
        right:0px;
        background: url('../assets/svg/footer-turquoise-shape.svg') no-repeat;
        display: block;
        width: 73px;
        height:71px;
    }

    border-top:40px solid $brand-teal;
    h4,h5,h6{
        color:#fff;
    }
}
#wb-info .gc-main-footer{
    background:none;
    h4::before{
      display: none;  
    }
}
#wb-info .landscape{
    background: $brand-primary!important;
    h3{
        color:#fff;
        font-size: 16px;
        margin-top:3px!important;
    }
    .col-sm-6.np-left{
        min-height: 130px;
    }
    a{
        font-size: 16px;
    }
    .list-unstyled.list-inline li{
        padding-right:45px;
        &:last-child{
            padding-right: 0px;
        }
    }
}   
#wb-info .gc-sub-footer ul li{
    margin-bottom:0.25em;
}
#wb-info .gc-sub-footer ul li:not(:first-child){
    padding-left:0px;
}
#wb-info .gc-sub-footer ul li:not(:first-child)::before{
    content: "\2022";
    margin-right: 0.25em;
}
.webform-submission-report-a-problem-on-this-page-form legend{
    margin-bottom:8px;
}