@include bg-variant('.bg-dark', $gray-dark);

@include bg-variant('.bg-primary', $brand-primary);
@include bg-variant('.overlay-def.wb-bar-b', $brand-primary);

@include bg-variant('.bg-teal', $brand-teal);
@include bg-variant('.bg-secondary', $brand-teal);

@include bg-variant('.bg-orange', $brand-orange);

@include bg-variant('.bg-default', $offwhite);


.bg {
    &-orange {
      color: $gray-base;
  
      @include typographyColours(
        (
          "text": $gray-base,
          "heading": $brand-dark,
          "promo-text": $gray-base,
          "link-color": $gray-base,
          "link-visited": $link-visited,
          "link-hover-color": $link-hover-color,
          "list-accent": $gray-base,
          "link-btn-color": $gray-base,
          "link-btn-readmore-color": $gray-base,
          "link-btn-hover-color": $gray-base,
          "link-btn-contrast": $white,
          "link-btn-accent": $white,
          "link-btn-primary": $secondary,
          "btn-arrow": $primary,
          "btn-arrow-hover": $gray-base,
          "link-btn-primary-color": $gray-base,
          "link-btn-secondary-color": $gray-base,
          "link-btn-secondary": $gray-base,
          "link-btn-primary-bg": transparent,
        )
      );
    
      * {
        color: $gray-base;
      }
    }

    &-teal{
        @include typographyColours(
        (
          "text": $gray-base,
          "heading": $brand-primary,
          "promo-text": $brand-primary,
          "link-color": $gray-base,
          "link-visited": $link-visited,
          "link-hover-color": $link-hover-color,
          "list-accent": $gray-base,
          "link-btn-color": $gray-base,
          "link-btn-readmore-color": $gray-base,
          "link-btn-hover-color": $gray-base,
          "link-btn-contrast": $primary,
          "link-btn-accent": $secondary,
          "link-btn-primary": $secondary,
          "btn-arrow": $primary,
          "btn-arrow-hover": $gray-base,
          "link-btn-primary-color": $gray-base,
          "link-btn-secondary-color": $gray-base,
          "link-btn-secondary": $gray-base,
        )
      );
    }
}