//@import url('https://fonts.googleapis.com/css?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,.h1{
    font-size: 58px;
    color:$brand-dark;
    border-bottom:none;
    // margin-bottom:0px;
    // padding-bottom:0px;
    
    @media screen and (max-width:768px) {
      font-size: 30px;
    }
    
}
h1{
  &:after{
      content:'';
      width:100px;
      display: block;
      margin-top: 30px;
      //margin-bottom: 20px;
      border-bottom:8px solid $brand-orange;
      @media screen and (max-width:768px) {
          width:50px;
          margin-top: 15px;
          //margin-bottom: 10px;
      }
  }
}
h1#wb-cont{
  border:none;
}
h2, .h2{
    font-size: 38px;
    font-weight: bold;
    color:$brand-primary;
    @media screen and (max-width:768px) {
      font-size: 24px;
    }
}
h3, .h3{
    font-size: 28px;
    font-weight: 600;
    color:$brand-primary;
    @media screen and (max-width:768px) {
      font-size: 20px;
    }
}
h4,.h4{
    font-size: 22px;
    font-weight: 600;
    color:$brand-primary;
    @media screen and (max-width:768px) {
      font-size: 18px;
    }
}
h5,.h5{
  color:$brand-primary;
}
h6,.h6{
  color: $brand-primary;
}
// Ensure all links are underlined in content.
.region-content a{
  text-decoration: underline;
}
// Ensure Title links are not shown as visited.
a.title--link:visited{
    color: inherit;
  }

  // Fix contextual links
  .contextual a{
    color:#333!important;
  }

  .bold-text{
    font-weight: bold;
  }

  hr{
    border-top-color:$dark;
  }
  .panel-heading h2,
  .panel-heading h3{
    margin-top:0px;
    margin-bottom:0px;
  }

ul#{$ul-exclusions},
ol#{$ol-exclusions}{
  li{
    //margin-bottom: 10px;

    &::marker{
      color: $brand-primary;
      font-size: 22px;
      line-height: 1;
    }

    &:last-child{
      margin-bottom: 0;
    }

    .bg-primary &,
    .bg-dark &{
      &::marker{
        color: $white;
      }
    }
    // Removed extra nested list spacing as client didn't like extra spacing. CL.
    // ul#{$ul-exclusions},
    // ol#{$ol-exclusions}{
    //   margin-top: 10px;
    // }
  }
}

ol#{$ol-exclusions}{
  li{
    &::marker{
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.Indent1 {
  margin-left: 3.4rem;
}
.Indent2 {
  margin-left: 6.8rem;
}
.Indent3 {
  margin-left: 10.2rem;
}
.Indent4 {
  margin-left: 13.6rem;
}
.Indent5 {
  margin-left: 17rem;
}
.Indent6 {
  margin-left: 20.4rem;
}
.Indent7 {
  margin-left: 24rem;
}
.Indent8 {
  margin-left: 27.4rem;
}
.Indent9 {
  margin-left: 30.8rem;
}
.Indent10 {
  margin-left: 34.2em;
}
