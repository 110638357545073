.transparency-contracts.full .field--label-inline{
    display: flex;
    .field--label{
        font-weight: bold;
        min-width:240px;
        &:after{
            content: ':';
            display: inline-block;
        }
    }
}

.lang-fr{
    .transparency-contracts.full .field--label-inline{
        .field--label{
            &:after{
                margin-left:4px;
            }
        }
    }
}