.site-section{
    &.banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 300px;
        position: relative;

        p.h1{
            margin: 0;
            color: $white;
        }
    }
}