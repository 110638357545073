.home .align-center img{
    margin:0 auto;
}
.home h2{
    font-size:38px;
    margin-top:28px!important;
}
.home h2.h1{
    font-size: 58px;
    @media screen and (max-width:768px) {
        font-size: 30px;
    }
}
.home .spotlight--hero{
    margin-top:0px;
}
.home .column p.hght-inhrt{
    font-size: 18px;
}
.home .column .wxt-media-default{
    margin-top:40px;
    img{
        //max-width: 75px;
        max-height: 68px;
    }
}

.home .column.col-sm-3{
    border-right:1px solid $brand-orange;
    &:last-of-type{
        border-right: 0px;
    }
    > .field--item{
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height:100%;
        
    }
    &:first-of-type{
        > .field--item{
            padding-left: 0px;
        }
    }
    &:last-of-type{
        > .field--item{
            padding-right:0px;
        }
    }
}