.wb-mltmd {
  margin-top: 0px;
  &.video:not(.playing):not(.waiting) {
    .display {
      border-radius: $border-radius-base $border-radius-base 0 0;
      overflow: hidden;

      &:before {
        background: $brand-primary !important;
        border-radius: 50%;
        transition: all 300ms ease-in-out;
      }

      &:after {
        font-size: 50px;
        line-height: 100px;
        transition: all 300ms ease-in-out;
      }

      &:hover,
      &:focus {
        &:before,
        &:after {
          transform: scale(1.1) translateY(-2px);
        }
        &:after {
          font-size: 60px;
        }
      }
    }
  }

  .shr-opn {
    @extend .btn;
    @include button-size(
      $padding-small-vertical,
      $padding-small-horizontal,
      $font-size-small,
      $line-height-small,
      $border-radius-base
    );
    
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 8px 8px;
    position: absolute;
    left: 25px;
    text-transform: initial;
    bottom: -12px;
    width: auto;

    span.glyphicon {
      display: none;
    }

    @media screen and (max-width: $screen-xs-max) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 25px;
      padding: 5px 8px;
      writing-mode: inherit;
    }
  }

  .wb-mm-ctrls {
    align-items: center;
    background: $gray-darker;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    color: $white;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    .btn {
      background: none !important;
      color: $white !important;
      padding: 0;
      border:none!important;

      .glyphicon {
        top: 0;
        color: $white;
        transition: all 300ms ease-in-out;

        &-subtitles {
          // &:before {
          //   @include fontawesome("\f20a");
          // }
        }
      }

      &:hover,
      &:focus {
        color: $brand-info !important;
        transform: scale(1.05);

        .glyphicon {
          color: $brand-info !important;
        }
      }
    }

    .frstpnl {
      display: block;
      width: auto;

      input[type="range"] {
        cursor: pointer;
        height: 36px;
        width: 5em;

        &::-moz-range-track,
        &::-webkit-slider-runnable-track {
          background: $gray-light;
          border-radius: 2px;
          height: 2px;
          transition: height 300ms ease-in-out;
        }

        &::-moz-range-progress,
        &:focus::-webkit-slider-runnable-track {
          background: $brand-info;
          border-radius: 2px;
          height: 2px;
          transition: height 300ms ease-in-out;
        }

        &::-moz-range-thumb,
        &::-webkit-slider-thumb {
          background: $brand-info;
          border: none;
          border-radius: 50%;
          height: 4px;
          width: 4px;
          transition: all 300ms ease-in-out;
        }

        &:hover,
        &:focus {
          &::-moz-range-track,
          &::-webkit-slider-runnable-track {
            height: 5px;
          }

          &::-moz-range-progress,
          &:focus::-webkit-slider-runnable-track {
            height: 4px;
          }

          &::-moz-range-thumb,
          &::-webkit-slider-thumb {
            height: 7px;
            width: 7px;
          }
        }
      }

      @media screen and (max-width: $screen-xs-max) {
        padding: 0;
      }
    }

    .tline {
      display: block;
      width: auto;
    }

    .lastpnl {
      display: block;
      width: auto;

      @media screen and (max-width: $screen-xs-max) {
        padding: 0;
      }
    }

    .wb-mm-txtonly {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      left: 0;
      padding: 5px;
      position: absolute;
      top: 0;
      width: 100%;

      p {
        margin-bottom: 0;

        &.wb-mm-tmln-crrnt {
          &:after {
            display: none;
          }
        }
      }

      @media screen and (max-width: $screen-xs-max) {
        margin: 0;
      }
    }

    progress {
      border: none;
      border-radius: 0;
      cursor: pointer;
      height: 3px;
      padding: 0;
      left: 0px;
      transition: all 300ms ease-in-out;

      &::-moz-progress-bar,
      &::-webkit-progress-value {
        background: $brand-primary;
        border-radius: 0;
      }

      &:hover,
      &:focus {
        height: 10px;
        top: -7px;
      }
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 15px;
  }
}

.bg {
  &-primary {
    .shr-opn {
      .glyphicon {
        color: $text-color;
      }
    }
  }
}
