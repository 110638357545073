.bg-img{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.parallax{
        background-position: 50% 100%;
        background-size: auto 130%;
    }

    &.cover{
        background-size: cover;
    }
}