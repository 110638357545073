details:not(.timeline-group):not(.accordion-unstyled):not(.wb-fnote){
    summary{
        border-radius: 0px;
        border: 1px solid $brand-teal;
        color:$gray-base;
        font-size: 18px;
        padding:20px 0px; 
        font-weight: 700;
        border-left-width: 70px;
        &::marker{
            color:$brand-orange;
            content:'';
        }
        &::before{
            transition: transform 0.3s ease-in-out;
            content:'';
            margin-right:50px;
            margin-left:-40px;
            display: block;
            float:left;
            width: 30px; 
            height: 30px;
            background: url('../assets/svg/down-arrow.svg') no-repeat;
            transform: rotate(-90deg);
        }
        &:focus,
        &:hover{
            font-weight: 700;
            color:$black;
            text-decoration: none;
        }
    }
    
    &.block--report-a-problem{
        summary{
            border-top:3px solid $brand-teal;
            border-bottom:3px solid $brand-teal;
            border-left:3px solid $brand-teal;
            border-right:3px solid $brand-teal;
            border-radius: 20px;
            padding: 10px 25px;
            font-weight: 400;
            &:before{
                margin-left:0px;
                margin-right:15px;
                border-left-color: $brand-orange;
                background-image: url('../assets/svg/down-arrow-orange.svg');
            }
            &:focus,
            &:hover{
                font-weight: 700;
                color:$black;
                text-decoration: none;
            }
        }
    }
    &[open]{
        border-radius: 0px;
        border: 1px solid $brand-teal;
        &>summary{
            border:0px;
            font-weight: 700;
            padding-bottom:5px;
            &:before{
                transform: rotate(0deg);
                margin-left: 30px;
            }
        }
        &.block--report-a-problem{
            border-top:3px solid $brand-teal;
            border-left:3px solid $brand-teal;
            border-right:3px solid $brand-teal;
            border-radius: 20px 20px 20px 20px;
            summary{
                border:0px;
                border-radius: 0px;
                padding: 10px 25px;
                &:before{
                    margin-left:0px;
                }
            }
        }
    }
    &.borderless{
        summary{
            border-top-width: 0px;
            border-right-width: 0px;
            border-bottom-width: 0px;
        }
        &[open]{
            border:0px;
        }
    }
}
details:not(.block--report-a-problem):not(.timeline-group):not(.accordion-unstyled){
    .details-wrapper{
        border-left:13px solid $brand-teal;
        margin-left:70px;
        padding-left:15px;
    }
}