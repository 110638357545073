.paragraph--type--wdcb-doormat{
    border-right:2px solid $offwhite;
    margin-bottom:60px;
    &:nth-child(odd){
        .padded-md{
            padding-left:0px;
        }
    }
    &:nth-child(even){
        .padded-md{
            padding-right:0px;
        }
    }
    &:nth-child(2n){
        border-right: 0px;
    }
    .field--name-field-icon{
        min-height:110px;
        img{
            min-height: 110px;
            max-height: 110px;
            width:auto;}
        @media screen and (max-width:768px) {
            min-height:55px;
            img{min-height: 55px;max-height:55px;width:auto;}
          }
    }
    .doormat-description{
       //min-height:130px;
        //flex-grow: 2;
    }
    .btn.btn-primary{
        align-self: flex-start;
    }
}
.has-doormats{
    border-bottom:4px solid $brand-teal;
    padding-bottom:60px;
    margin-top:40px;
}
.bg-orange{ 
    .has-doormats{
        border-bottom:none;
        padding-bottom:0px;
    }
    .field--name-field-icon{
        min-height:110px;
        img{min-height: 75px;height:75px;}
    }
}