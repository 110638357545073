::-webkit-input-placeholder ,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
    color: $brand-dark!important;
    opacity:1;
}
.news-filters{
    background-color: $light;
    > .container{
        position: relative;
        .btn-primary.pull-right{
            position: absolute;
            top:-20px;
            right:15px;
        }
    }

    .views-exposed-form .form--inline{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        column-gap:50px;
        grid-template-rows: 1fr 1fr;
        row-gap:20px;
        width: 100%;
        @media screen and (max-width:768px) {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            row-gap:0px;
            column-gap:10px;
        }
    }
    .control-label{
        color:$brand-primary;
        margin-right: 15px;
    }
    .select-wrapper{
        position: relative;
        select{
            border-radius: 8px; 
            -webkit-appearance: none;
            appearance:none;
            padding-right:43px;
            width: 100%;
            background:#fff url('../assets/svg/select-arrow.svg') no-repeat center right; 
            background-size:44px;
        }
        
    }
    .form-control{
        border:1px solid $brand-primary;
        border-radius: 8px;
        &::-webkit-input-placeholder ,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
            color: $brand-dark!important;
        }
        
    }
    ::placeholder{
        color:$brand-dark;
    }
   
    .form-item-search,
    .form-item-r-s{
        grid-column: 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        .control-label{
            min-width: 120px;
        }
        .form-control{
            flex-grow: 1;
        }
        @media screen and (max-width:768px) {
            display: block;
        }
    }
    .form-item-type{
        grid-column: 1;
        grid-row: 2;
        display: flex;
        align-items: center;
        .control-label{
            min-width: 120px;
        }
        .select-wrapper{
            flex-grow: 1;
        }
        @media screen and (max-width:768px) {
            display: block;
        }
    }
    .form-item-date{
        display: flex;
        align-items: center;
        grid-column: 2;
        grid-row: 2;
        .form-control{
            display: inline-block;
            padding-right:70px;
        }
        @media screen and (max-width:768px) {
            display: block;
            grid-column: 1;
            grid-row:3;
        }
    }
    .form-item-items-per-page,
    .form-items-items-page{
        text-align: right;
        grid-column: 3;
        grid-row: 2;
        .select-wrapper{
            display: inline-block;
        }
        @media screen and (max-width:768px) {
            display: block;
            grid-column: 1;
            grid-row: 4;
        }
    }
    .form-actions{
        grid-column: 2;
        grid-row: 1;
        align-self: center;
    }
    .form-submit{
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            padding-left:0px;
            top:8px;
        }
        @media screen and (max-width:768px) {
            margin-top: 25px;
        }
    }

}

.view-news-feed{
    .content.row{
        display: flex;
        align-items: center;
        h3{
            margin-top:0px;
        }
        .news--date{
            margin-bottom:0px;
        }
    }
}