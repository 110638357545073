#block-sitesection{

    .field--name-field-site-section{
        background-color:$brand-teal;
        position: relative;
        border-bottom-right-radius: 50px;
        &:before{
            content:'';
            position: absolute;
            top:0;
            right:0;
            display: block;
            width: 197px;
            height:153px;
            background: url('../assets/svg/bubbles-for-teal.svg') no-repeat top right;
            background-size: 197px;
            @media  screen and (max-width:768px) {
                width: 75px;
                background-size: 75px;
            }
        }
        .h1{
            font-size: 48px;
            padding: 70px 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            display: block;
            @media  screen and (max-width:768px) {
                font-size: 28px;
                padding: 35px 0px;
            }
        }
    }
}
.page-node-type-general-content{
    #block-sitesection{
        .field--name-field-site-section{
            background-color:$offwhite;
            &:before{
                background: url('../assets/svg/bubbles-for-offwhite.svg') no-repeat top right;
                background-size: 197px;
                @media  screen and (max-width:768px) {
                    width: 75px;
                    background-size: 75px;
                }
            }
        }
    }
}