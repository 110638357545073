figure{
  &.wxt-media-figure{
    img.thumbnail{
      margin-bottom: 0;
    }

    figcaption{
      border-bottom: 1px solid;
      color: $brand-primary;
      font-size: 14px;
      margin-bottom: $padding-xs;
      line-height: normal;
      padding-bottom: $padding-xs;
      width: 100%;

      *:last-child{
        margin-bottom: 0;
      }
    }
  }
}