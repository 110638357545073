.alert-info > :first-child::before, .alert-success > :first-child::before, .alert-warning > :first-child::before, .alert-danger > :first-child::before {
    color: inherit;
    content: none; }
  
  .alert {
    background-clip: content-box;
    background-color: inherit;
    border:0px;
    color: $text-color;
    border-left: 6px solid $text-color;
    border-radius: 0px;
    margin-bottom: 23px;
    margin-left: 10px;
    padding: 0 0 0 15px; }
    .alert > p, .alert > ul, .alert > ol {
      margin-bottom: 0px; }
    .alert > * {
      margin-left: 15px; }
    .alert details {
      margin-left: .5em;
      padding-top: 15px; }
    .alert > :first-child:not(details) {
      margin-top: auto;
      padding-top: 15px; }
    .alert > :last-child {
      padding-bottom: 25px; }
    .alert::before {
      font-family: "Glyphicons Halflings";
      font-size: 26px;
      line-height: 2.3em;
      margin-left: -1.27em;
      padding: 2px;
      position: absolute; }
    .alert > :first-child {
      margin-left: 15px; }
  .alert-info {
    border-color: #269abc;
    border-image: linear-gradient(to bottom, #269abc 16px, #269abc 16px, transparent 16px, transparent 48px, #269abc 48px, #269abc 48px) 1 100%; }
    .alert-info::before {
      color: #269abc;
      content: ""; }
  .alert-success {
    border-color: #278400;
    border-image: linear-gradient(to bottom, #278400 16px, #278400 16px, transparent 16px, transparent 48px, #278400 48px, #278400 48px) 1 100%; }
    .alert-success::before {
      color: #278400;
      content: ""; }
  .alert-warning {
    border-color: #ee7100;
    border-image: linear-gradient(to bottom, #ee7100 16px, #ee7100 16px, transparent 16px, transparent 48px, #ee7100 48px, #ee7100 48px) 1 100%; }
    .alert-warning::before {
      color: #ee7100;
      content: ""; }
  .alert-danger {
    border-color: #d3080c;
    border-image: linear-gradient(to bottom, #d3080c 16px, #d3080c 16px, transparent 16px, transparent 48px, #d3080c 48px, #d3080c 48px) 1 100%; }
    .alert-danger::before {
      color: #d3080c;
      content: ""; }
  