// colours
$white: #fff;
$black: #000;
$light: #f6f6f6;
$dark: #444;
$gray-base:#231F20;
$brand-primary: #304F5C;
$brand-dark: #1D2D34;
$brand-egplant: #452344;
$brand-egplant-light: #673955;
$brand-egplant-lighter:#AF6172;
$brand-salmon: #E96C57;
$brand-orange: #F9A453;
$brand-yellow: #ECC023;
//$brand-teal: #70C6B1;
$brand-teal: #78C9B5;
$offwhite: #EFEFE7;

// utilities
$padding: 40px!default;
$padding-large: 60px!default;
$padding-medium: 40px!default;
$padding-small: 20px!default;
$padding-xs: 15px!default;
$padding-medium-xs: 18px!default;
$padding-large-xs: 25px!default;
$padding-small-xs: 0!default;

$primary: $brand-primary;
$secondary: $brand-salmon;
$third: $brand-egplant;
$main-light: $offwhite;

// fonts
$font-family-base: "Barlow", sans-serif;
// $font-size-base: 16px; Feedback text too small. CL
$font-size-base: 18px;
$font-size-h1: 58px;
$font-size-h2: 38px;
$font-size-h3: 28px;
$font-size-h4: 22px;
$font-size-h5: 18px;
$font-size-h6: 16px;
// $headings-font-family: $font-family-base;
$headings-color: $brand-primary;
$headings-font-weight: 600;
$headings-line-height: 1.3;
$text-color: $gray-base;

//$line-height-base: 1.8; CL Changed upon client request to tighten spacing.
$line-height-base: 1.6;
//$headings-font-family: $font-family-base;
$font-second: $font-family-base;
$font-third: $font-family-base;

/*** Responsive ***/
$col-xl: 1200px;
$col-lg: 992px;
$col-md: 768px;
$col-sm: 576px;
$col-xs: 576px;

// utilities
$padding: 40px !default;
$padding-large: 60px !default;
$padding-medium: 32px 53px!default;
$padding-small: 20px !default;
$padding-xs: 15px !default;
$padding-large-xs: 25px !default;
$padding-medium-xs: 15px 20px!default;
$padding-small-xs: 0 !default;

// Glyphicons
$icon-font-path: "../vendor/bootstrap/assets/fonts/bootstrap/";

// Navbar{
$navbar-default-link-color: $text-color;

// Custom List styles
$ul-exclusions: ":not(.nav):not(.menu):not(.dropdown-menu):not(.toolbar-menu):not(.contextual-links):not(.links):not([role="tablist"]):not(.list-group):not(.list-inline):not(.select2-selection__rendered):not(.select2-results__options)";
$ol-exclusions: ":not(.breadcrumb)";
$list-spacing: 0.875em;

// buttons
$btn-border-radius-base: 5px;
$btn-primary-color: $white;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $brand-primary;

$colours: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $brand-teal,
  "third": $third,
  "light": $light,
  "dark": $dark,
);

/*** Fonts ***/
$font-family-base: "Barlow", sans-serif;
$headings-font-family: "Barlow", "sans-serif", system-ui;
$headings-alt-font-family: "Barlow", "sans-serif", system-ui;
$font-second: $headings-font-family;

/*** Responsive ***/
$col-xl: 1200px;
$col-lg: 992px;
$col-md: 768px;
$col-sm: 576px;
$col-xs: 576px;

/*** Link ***/
//** Global textual link color.
$link-color: #0535d2;
$link-visited: #7834bc;
//$link-hover-color: #0535d2;
$link-hover-decoration: underline;
$table-border: #c9cfda;

/*** Text ***/
$text: $text-color;
$text-button: #181914;
$text-second: #222629;
$font-color: $dark;
$heading-colour: $primary;
$promo-text: $gray-base;
$list-accent: $primary;
$input-color-placeholder: #333;
$typography-colours: (
  "text": $text,
  "heading": $heading-colour,
  "promo-text": $promo-text,
  "link-color": $link-color,
  "link-visited": $link-visited,
  //"link-hover-color": $link-hover-color,
  "list-accent": $primary,
);

// Custom List styles
$ul-exclusions: ':not(.nav):not(.menu):not(.dropdown-menu):not(.toolbar-menu):not(.contextual-links):not(.links):not([role="tablist"]):not(.list-group):not(.list-inline):not(.select2-selection__rendered):not(.select2-results__options)';
$ol-exclusions: ":not(.breadcrumb)";
$list-spacing: 0.875em;

// Glyphicons
$icon-font-path: "../vendor/bootstrap/assets/fonts/bootstrap/";
