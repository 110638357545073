.region-breadcrumb{
    background-color:$white;
    border-bottom:2px solid $brand-orange;
    .breadcrumb{
        background: $white;
    }
    #wb-bc ol{
        padding-left:0px;
    }
    #wb-bc li{
        vertical-align: middle;
        &:before{
            content:'';
            border-left: 8px solid $brand-orange;
            border-top: 8px solid  transparent;
            border-bottom: 8px solid  transparent;
            width: 15px;
            display: inline-block;
            vertical-align: middle;
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
    }
}