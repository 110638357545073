.navbar-btn.logo{
    padding:15px 0px;
}
.navbar{
    border: none;
    border-radius: 0;
    margin-bottom: 0px;
    
    
    #wb-sm {
        background: $brand-primary;
    }
    @media screen and (max-width: 1199px) {
        #wb-sm .menu{
            border-right:0px;
        }
    }
    
    #wb-sm .menu>li a{
        font-size: 18px;
        border-bottom:7px solid $brand-primary;
        border-top:7px solid $brand-primary;
        transition: all 300ms ease-in-out;
        padding: 0.7em 1.078em;

    }
    #wb-sm .menu .active, 
    #wb-sm .menu .selected, 
    #wb-sm .menu .wb-navcurr,
    #wb-sm .menu>li a:focus, 
    #wb-sm .menu>li a:hover{
        background: $brand-primary!important;
        border-bottom:7px solid $brand-orange;
        font-weight: 600;
        //padding: 1em;
        //color:$black;
    }
    #wb-sm .menu>li{
        border-left:0px;
        border-right:0px;
    }
    &-header{
        float: none;
    }

    &-toggle{
        /* .glyphicon{
            display: none;
        } */
    }
}
h2#topicsMenu{
    color:#fff!important;
}
