@mixin padded{
  padding: $padding!important;

  @media screen and (max-width: $screen-xs-max) {
    padding: $padding-xs!important;
  }
}

@mixin padded-small{
  padding: $padding-small;

  @media screen and (max-width: $screen-xs-max) {
    padding: $padding-small-xs;
  }
}

@mixin padded-medium{
  padding: $padding-medium;

  @media screen and (max-width: $screen-xs-max) {
    padding: $padding-medium-xs;
  }
}

@mixin padded-large{
  padding: $padding-large;

  @media screen and (max-width: $screen-xs-max) {
    padding: $padding-large-xs;
  }
}

@mixin vertical-padding{
  padding-bottom: $padding;
  padding-top: $padding;

  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: $padding-xs;
    padding-top: $padding-xs;
  }
}

@mixin vertical-padding-small{
  padding-bottom: $padding-small;
  padding-top: $padding-small;

  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: $padding-small;
    padding-top: $padding-small;
  }
}

@mixin vertical-padding-large{
  padding-bottom: $padding-large;
  padding-top: $padding-large;

  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: $padding-large-xs;
    padding-top: $padding-large-xs;
  }
}

@mixin no-padding{
  padding: 0!important;

  &-x{
      padding-left: 0!important;
      padding-right: 0!important;
  }

  &-y{
      padding-top: 0!important;
      padding-bottom: 0!important;
  }
}

@mixin no-padding-x{
  padding-left: 0!important;
  padding-right: 0!important;
}

@mixin no-padding-y{
  padding-top: 0!important;
  padding-bottom: 0!important;
}