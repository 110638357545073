table,
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 22px;

  &.dataTable {
    thead {
      .sorting-icons {
        display: inline-flex !important;
        flex-direction: column;
        float: right;

        &:before,
        &:after {
          background: none !important;
          border: none !important;
          color: transparentize($white, 0.6);
          height: 0;
          padding: 0;
          top: 5px;
        }

        &:after {
          left:15px;  
        }
      }

      .sorting .sorting-icons::after,
      .sorting .sorting-icons::before {
        color: transparentize($white, 0.6);
      }

      .sorting_asc,
      .sorting_desc {
        background: $brand-info;
        color: $text-color;

        .sorting-icons {
          &:before,
          &:after {
            color: transparentize($text-color, 0.6);
          }
        }
      }

      .sorting_asc {
        .sorting-icons {
          &:before {
            color: $text-color;
          }
        }
      }

      .sorting_desc {
        .sorting-icons {
          &:after {
            color: $text-color;
          }
        }
      }
    }
    
    tbody{
      color:$text-color;
    }
  }
}

.dataTables_wrapper {
  @extend .bg-primary;
  border: 1px solid $brand-primary;
  border-radius: $border-radius-small;

  .top {
    padding: 10px 15px;
    color:$white;
  }

  caption {
    color: $white !important;
    padding: 0 15px 15px;
  }

  .dataTables_filter input,
  .dataTables_length select {
    @extend .form-control;
    @extend .input-sm;
    display: inline-block;
    width: auto;
  }

  .dataTables_length select {
    padding-right: 15px;
  }

  .dataTables_filter, 
  .dataTables_info, 
  .dataTables_length, 
  .dataTables_processing{
    color:$white;
  }
}
