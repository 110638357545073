.news-release--notice{
    text-transform: uppercase;
    color:#606060;
    strong{
        font-weight: 900;
    }
    font-size: 18px;
}
.news--date{
    font-size: 18px;
    color:#606060;
    font-weight: 600;
}

.news.full{
    h1{
        font-size: 44px;
        line-height: 63px;
        font-weight: 600;
        border-bottom:0px;
    }
    h2{
        font-weight: 600;
    }
    .field--name-field-quick-facts,
    .field--name-field-related-links{
        .field--label{
            margin-top:0px;
        }
    }
    .news-footer-contact-icon{
        align-self: center;
        img{
            padding-right:45px;
            border-right: 2px solid $white;
        }
    }
}

  .news--icon{
    background-color:$brand-orange;
    width: 125px;
    height:125px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width:768px) {
        min-width: 95px;
        width: 95px;
        height: 95px;
    }
  }
  
  @media screen and (max-width:768px) {
    article.news.feed{
        margin-left: 10px;
    }
}