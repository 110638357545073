.content > .page-section:first-of-type{
  padding-top:20px;
}
.column {
  &.no-bg {
    display: block;
  }
  h2:first-child{
    margin-top:0px;
  }
  .padded-md div h2:first-child{
    margin-top:0px;
  }
  &.accent-border-right{
    border-right:2px solid $white;
    .img-responsive{
      width:110px;
    }
    .align-center{
      img{
        margin:0 auto;
      }
    }
  }
}
