.view-publications-feed{
    .content.row{
        display: flex;
        align-items: center;
        h3{
            margin-top:0px;
        }
        .news--date{
            margin-bottom:0px;
        }
    }
}