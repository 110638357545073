.btn {
  border-width: 2px;
  font-family: $font-family-base;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none!important;
  white-space: normal !important;
  transition: all 400ms ease-in-out;
  @include button-size(0.7em, 1em, 1.125em, 1.2, 12px);
    // button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius
  
    &-primary:hover,
    &-primary.hover{
        background-color:$brand-orange!important;
        color: $black!important;
        border-color:$brand-orange!important;
    }
    &-primary:visited{
      color: $brand-primary;
    }

    &-secondary:hover,
    &-secondary.hover{
        background-color: $brand-primary!important;
        color:$white!important;
        border-color: $brand-primary!important;
    }

    &-default,
    &-default:visited {
      font-family: $font-second;
      border: 2px solid $brand-orange !important;
      color: $brand-primary;
    }

    &-default:hover{
        background-color:$brand-orange!important;
        color:$black!important;
    }
  
    &-link,
    &-link:visited {
      font-weight: 600;
      font-family: $font-second;
      font-size: 1.5em;
  
      line-height: normal;
      border-bottom: 3px solid transparent;
      min-width: unset;
      width: fit-content;
      padding: 0;
      padding-top: 0;
      border-radius: 0;
      position: relative;
      margin: 0 18px 0 0;
  
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &.hover {
        text-decoration: none;
        padding-top: 0;
  
        border-bottom: 3px solid $brand-primary;
      }
    }
  }
  .pager__item.is-active a,
  .pager__item.is-active a:hover{
    color:#fff;
  }
  .wb-share .btn{
    &-default,
    &-default:visited {
      font-family: $font-second;
      border: 2px solid $brand-teal !important;
      color: $brand-primary;
    }

    &-default:hover{
        background-color:$brand-teal!important;
        color:$black!important;
    }
  }
  .btn{
    &-info,
    &-info:visited {
      font-family: $font-second;
      border: 2px solid $brand-teal !important;
      color: $brand-primary;
    }

    &-info:hover{
        background-color:$brand-teal!important;
        color:$black!important;
    }
  }