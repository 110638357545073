.spotlight--hero{
    // background: url('../assets/jpg/home--hero.jpg') no-repeat center center;
    // background-size: cover;
    min-height: 600px;
    border-bottom:30px solid $brand-orange;
    position: relative;
    padding-bottom:160px;
    margin-top:30px;
    display: flex;
    align-items: center;
    @media screen and (max-width:768px) {
        min-height: auto;
        &#paragraph-390{
            background-size: 1550px;
            background-position-x: -710px!important;
        }
    }

    .hero--title{
        max-width: 50%;
        font-weight: 600;
        line-height: 1.2;
        margin-top: 110px;
        margin-bottom:0px;
        &:after{
            content:'';
            width:100px;
            display: block;
            margin-top: 40px;
            margin-bottom: 20px;
            border-bottom:8px solid $brand-orange;
            @media screen and (max-width:768px) {
                width:50px;
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }

    .hero--slogan{
        font-size: 24px;
        color:#231F20;
        max-width: 50%;
        margin-bottom:0px;
        @media screen and (max-width:768px) {
            font-size: 18px;
        }
        
    }
    .hero--overlay{
        margin-top:30px;
        background: linear-gradient(90deg, #A96428 0%, #B76E2C 13.91%, #D07F35 31.15%, #EC9546 60.52%, #FFA451 83.55%, #FFA451 100%);
        //background: url('../assets/svg/orange-overlay.svg') no-repeat;
        background-size: cover;
        mix-blend-mode: multiply;
        padding:35px 0 35px 0;
        position: absolute;
        bottom:0px;
        width: 100%;
        
        &:before{
            content: '';
            position: absolute;
            top:-70px;
            right:0px;
            background: url('../assets/svg/header-orange-shape.svg') no-repeat;
            display: block;
            width: 73px;
            height:71px;
        }
    }
    .hero--button{
        font-size: 32px;
        color:#fff;
        font-weight: bold;
        border:3px solid #fff;
        border-radius: 15px;
        padding:15px 70px;
        transition: all 500ms ease;
        &:hover{
            background-color:#fff;
            color:$brand-primary;
            border-color: $brand-primary;
        }
        @media screen and (max-width:768px) {
            font-size: 24px;
        }
    }

    
}
.home .spotlight--hero{
    margin-top:0px;
    &:before{
        content:'';
        position: absolute;
        top:0px;
        right:0px;
        background: url('../assets/svg/speech-bubbles.svg') no-repeat top right;
        display: block;
        width: 350px;
        height: 290px;
        mix-blend-mode: multiply;
        @media screen and (max-width:768px) {
            background-size: cover;
            width: 150px;
            height: 124px;
        }
    }
    .hero--title{
        margin-top:50px;
        @media screen and (max-width:768px) {
            margin-top: 20px;
        }
    }
    .hero--overlay{
        @media screen and (max-width:768px) {
            mix-blend-mode: normal;
        }
    }
    @media screen and (max-width:768px) {
        &#paragraph-376{
            background-size: 1550px;
            background-position: center top!important;
        }
    }
}