.infographic{
  &.full{
    .field--name-field-pdf{
      margin-bottom: $padding-xs;
    }

    .wb-lbx{
      .thumbnail{
        margin-bottom: 0;
      }
    }
  }

  &.teaser{
    margin-bottom: $padding;
    position: relative;
    transition: all 300ms;

    h2{
      font-size: $font-size-h3;
      margin-top: 0;
      transition: all 300ms;

      a{
        transition: all 300ms;
      }
    }

    .content{
      background: $light;
      box-shadow: none;
      margin-bottom: 0;
      position: static;
      transition: all 300ms;

      @media screen and (max-width: $screen-xs-max) {
        border-radius: 0 0 $border-radius-base $border-radius-base;
      }
    }

    .image-wrapper{
      overflow: hidden;
      position: relative;

      img{
        @include object-fit;
        height: 100%;
        object-position: top center;
        position: absolute;
      }
      
      @media screen and (min-width: $screen-sm-min) {
        padding-left: 0;
        
        img{
          width: calc(100% - (#{$grid-gutter-width} / 2));
        }
      }

      @media screen and (max-width: $screen-xs-max) {
        border: 1px solid $well-border;
        border-radius: $border-radius-base $border-radius-base 0 0;
        height: 275px;

        img{
          left: 0;
        }
      }
    }
  }
}