@mixin typographyColours($colours) {
    $heading-colour: if(
      map-get($colours, "heading"),
      map-get($colours, "heading"),
      $heading-colour
    );
  
    $list-accent: if(
      map-get($colours, "list-accent"),
      map-get($colours, "list-accent"),
      $list-accent
    );
  
    $btn-arrow: if(
      map-get($colours, "btn-arrow"),
      map-get($colours, "btn-arrow"),
      $white
    );
  
    $btn-arrow-hover: if(
      map-get($colours, "btn-arrow-hover"),
      map-get($colours, "btn-arrow-hover"),
      $black
    );
  
    $text: if(map-get($colours, "text"), map-get($colours, "text"), $text);
  
    $link-color: if(
      map-get($colours, "link-color"),
      map-get($colours, "link-color"),
      $link-color
    );
    $link-hover-color: if(
      map-get($colours, "link-hover-color"),
      map-get($colours, "link-hover-color"),
      $link-hover-color
    );
  
    $link-visited: if(
      map-get($colours, "link-visited"),
      map-get($colours, "link-visited"),
      $link-visited
    );
  
    $promo-text: if(
      map-get($colours, "promo-text"),
      map-get($colours, "promo-text"),
      $promo-text
    );
  
    $link-btn-color: if(
      map-get($colours, "link-btn-color"),
      map-get($colours, "link-btn-color"),
      $black
    );
  
    $link-btn-contrast: if(
      map-get($colours, "link-btn-contrast"),
      map-get($colours, "link-btn-contrast"),
      $white
    );
  
    $link-btn-accent: if(
      map-get($colours, "link-btn-accent"),
      map-get($colours, "link-btn-accent"),
      $secondary
    );
  
    $link-btn-primary: if(
      map-get($colours, "link-btn-primary"),
      map-get($colours, "link-btn-primary"),
      $primary
    );
  
    $link-btn-readmore-color: if(
      map-get($colours, "link-btn-readmore-color"),
      map-get($colours, "link-btn-readmore-color"),
      $black
    );
  
    $link-btn-hover-color: if(
      map-get($colours, "link-btn-hover-color"),
      map-get($colours, "link-btn-hover-color"),
      $black
    );
  
    $link-btn-primary-color: if(
      map-get($colours, "link-btn-primary-color"),
      map-get($colours, "link-btn-primary-color"),
      $white
    );
    $link-btn-primary-bg: if(
      map-get($colours, "link-btn-primary-bg"),
      map-get($colours, "link-btn-primary-bg"),
      $white
    );
    $link-btn-secondary-color: if(
      map-get($colours, "link-btn-secondary-color"),
      map-get($colours, "link-btn-secondary-color"),
      $white
    );
  
    $link-btn-secondary: if(
      map-get($colours, "link-btn-secondary"),
      map-get($colours, "link-btn-secondary"),
      $primary
    );
  
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4 {
      color: $heading-colour;
    }
  
    .link-hover {
      color: $link-hover-color !important;
    }
    // CL Commented until deemed needed, was overriding spans inside of elements.
    // p,
    // ul,
    // li,
    // span,
    // * {
    //   color: $text;
    // }
  
    hr {
      border-top-color: transparentize($text, 0.45);
    }
  
    a {
      &:not(.btn):not(.title--link) {
        color: $link-color;
        transition: all 250ms ease-in-out;
  
        &:hover,
        &:focus,
        &:focus-within {
          color: $link-hover-color;
        }
  
        &:visited,
        &.visited {
          color: $link-visited;
  
          &:hover,
          &:focus,
          &:focus-within {
            color: $link-hover-color;
          }
        }
      }
    }
  
    .promo-text,
    .pt {
      &-1,
      &-2,
      &-3,
      &-4,
      &-5,
      &-6 {
        color: $promo-text;
      }
    }
  
    ul#{$ul-exclusions},
    ol#{$ol-exclusions} {
      li {
        &::marker {
          color: $list-accent;
        }
      }
    }
    ul#{$ul-exclusions} {
      > li {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='#{$list-accent}' /%3E%3C/svg%3E");
        }
  
        > ul {
          > li {
            &::before {
              background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='7' height='7' rx='3.5' stroke='#{$list-accent}' stroke-width='2' /%3E%3C/svg%3E");
            }
  
            ul {
              > li {
                &::before {
                  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='#{$list-accent}' /%3E%3C/svg%3E") !important;
                }
              }
            }
          }
        }
      }
    }
  
    ol#{$ol-exclusions} {
      & > li:before {
        color: $list-accent;
      }
    }
  
    .btn {
      &-primary,
      &-primary:visited {
        border-color: $link-btn-accent !important;
        background-color: $link-btn-primary-bg !important;
        color: $link-btn-primary-color;
  
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &.hover {
          color: $link-btn-hover-color !important;
          background-color: $link-btn-contrast !important;
          border-color: $link-btn-accent !important;
  
          .glyphicon-search {
            &::before {
              color: $link-btn-accent !important;
            }
          }
        }
      }
  
      &-secondary,
      &-secondary:visited {
        border-color: $link-btn-secondary;
        background-color: $link-btn-secondary;
  
        color: $link-btn-secondary-color;
  
        &::after {
          // background-image: url(../assets/svg/btn-arrow-w.svg);
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 24' fill='none' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.93 5.92999L21 12L14.93 18.07' stroke='#{$btn-arrow}' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M4 12H20.83' stroke='#{$btn-arrow}' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
        }
        &::before {
          // background-image: url(../assets/svg/btn-arrow-hover.svg);
          background-image: url("data:image/svg+xml,%3Csvg width='47' height='24' viewBox='0 0 47 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.43 5.92999L43.5 12L37.43 18.07' stroke='#{$btn-arrow-hover}' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M1 12L43 12' stroke='#{$btn-arrow-hover}' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
          filter: drop-shadow(0px 0px 5px $link-btn-contrast);
        }
  
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &.hover {
          background: $link-btn-contrast !important;
          color: $link-btn-hover-color !important;
        }
      }
  
      &-link,
      &-link:visited {
        color: $link-btn-readmore-color;
  
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &.hover {
          color: $link-btn-readmore-color;
          border-bottom-color: $link-btn-accent;
        }
      }
    }
  }
  