span.form-required{
    &::after{
        content:'*';
        color:red;
        }
}
fieldset.webform-type-fieldset,
fieldset.fieldgroup{
    width: 100%;
}

.label-inline--190{
    min-width: 220px;
}

form {
    font-size: $font-size-base!important;
    line-height: $line-height-base!important;
  
    fieldset{
      @include clearfix;
  
      legend{
        @extend h2;
        border: none;
        margin-top: 0;
        font-size:28px;
        margin-bottom:0px;
      }
  
      .fieldset-wrapper{
        float: left;
        width: 100%;
      }
    }
  
    .form-control,
    output {
      color: $text-color !important;
  
      &::placeholder{
        color: $input-color-placeholder!important;
      }
  
      &:focus-within{
        color: $brand-primary;
      }
    }
  
    textarea{
      border-color: $input-border;
    }
  
    .form-item {
        margin-top:15px;
        &.checkbox,
        &.radio{
            margin-top:10px;
        }
      .control-label:not(.option) {
        font-size: 1em;
        color:$brand-primary;
        font-weight: bold;
        font-style: normal;
        margin-bottom: 10px;
        margin-right: 18px;
      }
  
      &.form-type-textfield {
        .control-label {
          width: fit-content;
          margin-right: 18px;
          align-self: center;
        }
  
        .form-text {
          flex: 3;
        }
      }
  
      &.text-format-wrapper{
        &:not(:last-child){
          margin-bottom: $padding-xs;
        }
      }
  
      strong {
        font-weight: 600;
      }
    }
    .form-submit{
      .icon.glyphicon{
        display: none;
      }
    }
    .form-actions{
      .form-submit{
        padding: 10px 20px 10px 20px !important;
        font-size: 18px;
      }
    }
  
    .checkbox,
    .radio{
      font-size: $font-size-base;
  
      input {
        appearance: none;
        background-color: transparent;
        border: 2px solid $gray-dark;
        color: currentColor;
        cursor: pointer;
        display: inline-flex;
        font: inherit;
        height: 25px;
        margin: 0px 10px 0 -20px;
        place-content: center;
        position: relative!important;
        width: 25px;
  
        &:before {
          content: "";
          width: 15px;
          height: 15px;
          display: inline-block;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em $brand-primary;
          background-color: $brand-primary;
          align-self: center;
          justify-self: center;
        }
        @media print {
          background-color:$brand-primary;
          border: 12px solid white;
          outline: 2px solid $brand-primary;
        }
        &:checked {
          border: 2px solid $brand-primary;
  
          &:before {
            transform: scale(1);
          }

          @media print {
            background-color:$brand-primary;
            border: 12px solid $brand-primary;
            outline: 2px solid $brand-primary;
          }
        }
      }
    }
  
    .radio {
      input[type="radio"] {
        border-radius: 50%;
      }
    
      input[type="radio"]::before {
        border-radius: 50%;
      }
    }
  }
  .description.help-block + .control-label{
    margin-top:0px;
  }

  // Overrides specific to the online complaint form.
  .webform-submission-filing-a-complaint-form fieldset.webform-type-fieldset legend{
    font-size: 22px;
    margin-top: 15px;
  }
  .webform-submission-filing-a-complaint-form #edit-personalinfo legend{
    @extend .sr-only;
  }