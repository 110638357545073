.icon-section{
    &--icon{
        padding-right:$padding-medium;
        .img-responsive{
            width: 110px;
        }
    }

    &--content{
        padding-left:$padding-medium;
        border-left:2px solid $white;
    }
}