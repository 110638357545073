.news-filters{
    .form-item-category,
    .form-item-cat{
        grid-column: 1;
        grid-row: 2;
        display: flex;
        align-items: center;
        .control-label{
            min-width: 120px;
        }
        .select-wrapper{
            flex-grow: 1;
        }
        @media screen and (max-width:768px) {
            display: block;
        }
    }
    .form-item-year,
    .form-item-a-y{
        display: flex;
        align-items: center;
        grid-column: 1;
        grid-row: 3;
        .form-control{
            display: inline-block;
            padding-right:70px;
        }
        @media screen and (max-width:768px) {
            display: block;
        }
    }
}