main{
    font-size: $font-size-base;
    line-height: $line-height-base;
}

body{
    .btn-default:not(.shr-opn):not(.panel-heading) {
        @include button-variant($brand-primary, #fff, $brand-orange);
    }
    .btn-primary {
        @include button-variant($brand-primary, $white, $brand-orange);
    }
    .btn-secondary {
        @include button-variant($brand-primary, $white, $brand-primary);
    }
    // Success appears as green
    .btn-success {
        @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
    }
    // Info appears as blue-green
    .btn-info {
        @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
    }
    // Warning appears as orange
    .btn-warning {
        @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
    }
    // Danger and error appear as red
    .btn-danger {
        @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
    }
}

.mrgn{
    &-tp-0{
        margin-top: 0!important;
    }
    &-bttm{
        &-0{
            margin-bottom: 0!important;
        }

        &-sm{
            margin-bottom: 5px!important;
        }
    }
    &-lft-0{
        margin-left: 0;
    }
    &-rght-0{
        margin-right: 0;
    }
}

.toc{
    li{
        display: block;
        a{
            font-size: $font-size-base;
        }
    }
}

.layout-builder{
    &-block{
        background: none;
    }

    &__link{
        color: $text-color!important;
    }
}

.overlay-def .modal-header{
    background-color:$brand-primary;
    .modal-title{
        color:#fff;
    }
    
}
.align-center.wxt-media-wdcb > img{
    margin:0 auto;
}
.block-date-modified-block{
    clear:left;
}

@media (min-width: 768px){
    .dl-horizontal dt {
        width:210px;
        text-overflow:initial;
        white-space:normal;
    }
    .dl-horizontal dd {
        margin-left: 220px;
    }

}

#wb-bc ol{
    margin-top:0px;
}