#block-customsearchblock .glyphicon,
#wb-srch .glyphicon{
    padding-left:0px;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
#block-customsearchblock  button,
#wb-srch button, 
.srchbox button{
    color:#fff;
    background-color: $brand-primary;
    border:none;
    border-radius: 0px 12px 12px 0px;
}
input#wb-srch-q,
#block-customsearchblock input[name="search"],
#block-customsearchblock input[name="r_s"]{
    border-radius: 12px;
    height: 44px;
}
#wb-srch,
#block-customsearchblock {
    position: relative;
    margin-top: 25px;
}
#wb-srch .submit, 
.srchbox .submit,
#block-customsearchblock .search-submit{
    position: absolute;
    right: 0;
    top: 4px;
    padding: 11px 18px;
}