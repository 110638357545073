.paragraph--type--wdcb-doormat{
    border-right:2px solid $offwhite;
    margin-bottom:60px;
    &:nth-child(3n){
        border-right: 0px;
    }
    .field--name-field-icon{
        min-height:110px;
    }
    .doormat-description{
        min-height:130px;
        flex-grow: 2;
    }
}
.has-doormats{
    border-bottom:4px solid $brand-teal;
    padding-bottom:60px;
}