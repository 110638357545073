.region-content{
    .block{
        opacity: 0;
        transform: translateY(50px);
        transition: all 500ms;

        &.in-view{
            opacity: 1;
            transform: translateY(0);
        }

        .form-group{
            margin-bottom: 0;
        }

        @media print{
            opacity: 1;
            transform: translateY(0);
        }
    }
}