.icon-section{
    
    
    &--icon{
        padding-right:$padding-medium;
        .img-responsive{
            width: 90px;
            min-width: 90px;
            @media screen and (max-width:768px) {
                width: 50px;
                min-width: 50px;
            }
        }
        @media screen and (max-width:768px) {
            align-self: start;
        }
    }

    &--content{
        padding-left:$padding-medium;
        border-left:2px solid $white;
        @media screen and (max-width:768px) {
            padding-left:0px;
            border-left:0px solid $white;
        }
    }
    
    
}
.landing-page.full .icon-section.page-section .content-title{
    margin-bottom:16px;
}
